import React, { useEffect } from "react";
import { graphql } from "gatsby";
import $ from "jquery";
import Layout from "@herb/layouts";
import Image from "@herb/components/Image";
import Slider from "@herb/components/Slider";
import SEO from "@herb/components/seo";
import BaseCard from "@herb/components/Cards/BaseCard.js";
import { Box, Container, Flex } from "@herb/ui";
import { paths, deployUrl } from "@herb/utils";
import CareerLocation from "@herb/components/Cards/CareerLocation";
import CareerBelieved from "@herb/components/CareerBelieved";
import Button from "@herb/components/Button";
import { scriptCreate } from "@herb/utils/scripts";
import "animate.css";
import "./index.css";
import animationObserver from "@herb/utils/animationObserver";

const Careers = ({ data }) => {
  const {
    allWordpressWpCareer: { edges: careers }
  } = data;
  const breadcrumbs = [
    {
      name: "Careers",
      url: `${paths.careers}/`
    }
  ];

  useEffect(() => {
    if (typeof window !== undefined) {
      $(window).ready(() => {
        if ($(".location-row-left").infiniteslide) {
          $(".location-row-left").infiniteslide({
            speed: 50
          });
          $(".location-row-right").infiniteslide({
            direction: "right",
            speed: 40
          });
        }
      });

      $(window).on("load resize scroll", function() {
        $(".scroll").each(function() {
          var scroll = $(window).scrollTop();
          var elementTop = $(".scroll").offset().top;
          var leftPosition = scroll - elementTop;
          $(".moving").css({ bottom: leftPosition });
        });
      });

      animationObserver.observe(document.querySelector(".animate-div"));
    }
    scriptCreate();
  }, []);
  return (
    <Layout>
      <SEO
        title="Careers"
        description="Careers Listings"
        url={paths.careers}
        canonical={`${deployUrl}${paths.careers}`}
        breadcrumbs={breadcrumbs}
      />
      <Box
        backgroundColor="white"
        justifyContent="space-around"
        // mb={[4, 5]}
        pt={[4, 4, 5]}
        css={{
          overflow: "hidden"
        }}
      >
        <Container>
          <div class="customFlex">
            <div className="customWidth50">
              <div className="leftSideTextCareer cusPB20px">
                <p className="herbLife">Life At Herb</p>
                <h1 className="joinSesh">Join the sesh</h1>
                <p className="cultureForCareer">
                  Culture is everything to us. It’s all about leadership,
                  empathy, finding purpose, and creating an environment where
                  people are free to grow, reach their goals and become the best
                  version of themselves.
                </p>
                <div className="buttonForCareer">
                  <Button to="/positions" type={"careerButton"}>
                    view open positions
                  </Button>
                </div>
              </div>
            </div>
            <div className="customWidth50">
              <Image
                className="lazyload"
                src="https://herb-platform-images.imgix.net/wp-content/uploads/2022/03/16093001/career-1.png"
                css={{ width: "100%" }}
              />
            </div>
          </div>
        </Container>

        <Container
          css={{
            marginTop: "152px",
            "@media screen and (max-width:1200px)": {
              marginTop: "60px"
            }
          }}
        >
          <div className="customFlex cusColumnReverse">
            <div className="customWidth40">
              <Image
                className="lazyload"
                src="https://herb-platform-images.imgix.net/wp-content/uploads/2022/03/16093127/career-2.png"
                width="100%"
              />
            </div>

            <div className="customWidth60 cusPB20px">
              <div className="rightSideTextCareer">
                <p className="peopleFirst">People First</p>
                <h1 className="ourPeople">Our people are the shit</h1>
                <p className="seriouslyGreat">
                  No, but seriously, they’re great.
                </p>
                <p className="ourPeopleFirst">
                  We put our people first. You give us 100%, we pay it back ten
                  times over. We love our people and want a long-term
                  relationship with them. Herb always puts mental health,
                  family, and personal growth first.
                </p>
              </div>
            </div>
          </div>
        </Container>
        <CareerBelieved />
        <Container>
          <Flex
            css={{
              flexWrap: "wrap",
              marginTop: "120px",
              alignItems: "flex-start",
              "@media screen and (max-width:768px)": {
                marginTop: "50px"
              }
            }}
          >
            <div className="animate-div creativeImg">
              <Image
                width={302}
                height={411}
                className="lazyload hideCareerAnimation animate_down customS-1"
                src="https://herb-platform-images.imgix.net/wp-content/uploads/2022/03/16091822/creativity1.png"
                attributeConfig={{
                  src: "data-src",
                  srcSet: "data-srcset",
                  sizes: "data-sizes"
                }}
                css={{
                  width: "100%",
                  maxWidth: "65%",
                  objectFit: "cover",
                  borderRadius: "16px",
                  position: "relative",
                  "@media screen and (max-width:769px)": {
                    height: "64%"
                  }
                }}
              />

              <Image
                width={302}
                height={411}
                className="lazyload hideCareerAnimation animate_right customS-2"
                src="https://herb-platform-images.imgix.net/wp-content/uploads/2022/03/16091856/creativity2.png"
                css={{
                  position: "absolute",
                  width: "100%",
                  maxWidth: "65%",
                  right: "0px",
                  top: "30%",
                  objectFit: "cover",
                  borderRadius: "16px",
                  "@media screen and (max-width:769px)": {
                    height: "62%",
                    objectPosition: "top"
                  }
                }}
              />
            </div>
            <div className="customWidth60">
              <div className="cusPadding64px">
                <p className="getItDone">How We Get It Done</p>
                <h2 className="creativity">Creativity is eating the world</h2>
                <p className="teamOfCreative">
                  We’re a team of creative problem solvers with constant ideas
                  after ideas. Creativity is at the core of what we do at Herb.
                  We collaborate as a team to go beyond our goals and maximize
                  impact.
                </p>
                <div className="careersSecondPara">
                  <p className="getsItDone">Who Gets It Done</p>
                  <h2 className="loveEnterpreneurs">We love entrepreneurs</h2>
                  <p className="somethingMagical">
                    Something magical happens when you work with people you
                    respect and who respect you. We have an entrepreneurial
                    culture where we value independence and a hands-on approach.
                    We are looking for others like us, people who can rise to
                    the challenge, create real strategies, and take action.
                  </p>
                </div>
              </div>
            </div>
          </Flex>
        </Container>

        <Box
          css={{
            textAlign: "center",
            marginTop: "120px",
            "@media screen and (max-width:430px)": {
              marginTop: "80px"
            }
          }}
        >
          <h1 className="nextRole">Discover your next role</h1>
        </Box>
        <Box
          css={{
            maxWidth: "1300px",
            margin: "0 auto",
            padding: "0 36px",
            paddingTop: "45px",
            marginBottom: "140px",
            "@media screen and (max-width:430px)": {
              padding: "0 18px"
            }
          }}
        >
          <div className="careerCardSlider">
            <Slider
              name={`careers`}
              slidesToShow={3}
              slidesToScroll={3}
              showMobile={false}
              css={{
                maxWidth: "1300px"
              }}
              responsive={[
                {
                  breakpoint: 760,
                  settings: { slidesToShow: 1, slidesToScroll: 1 }
                },
                {
                  breakpoint: 1240,
                  settings: { slidesToShow: 2, slidesToScroll: 2 }
                }
              ]}
            >
              {(careers || []).map(({ node }) => (
                <Box
                  key={node.id}
                  css={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "0px 15px",
                    width: "415px !important",
                    "@media screen and (max-width:1024px)": {
                      width: "unset !important",
                      padding: "0px 30px"
                    }
                  }}
                >
                  <BaseCard
                    css={{
                      marginBottom: "20px",
                      marginTop: "32px",
                      padding: "48px 24px 48px 24px",
                      // height: "490px",
                      borderRadius: "16px",
                      border: "1px solid #000",
                      background:
                        "linear-gradient(177.25deg, rgba(255, 243, 225, 0.5) 2.27%, rgba(247, 255, 227, 0.5) 47.99%, rgba(249, 204, 204, 0.5) 118.68%)"
                    }}
                  >
                    <h3 className="careerCardTitle">{node.title}</h3>

                    <p className="careerCardDescription">
                      {node.contentCareers.description}
                    </p>

                    <div className="buttonOfCareerCard">
                      <Button
                        type={"careerCardBtn"}
                        to={`/careers/${(node || {}).slug}`}
                      >
                        apply now
                      </Button>
                    </div>
                  </BaseCard>
                </Box>
              ))}
            </Slider>
          </div>
          <div className="forCareerCardButton">
            <Button type={"careerButton"} to="/positions">
              view all positions
            </Button>
          </div>
        </Box>
        <Box css={{ textAlign: "center" }}>
          <p className="getsItDone">Where It Gets Done</p>
          <h2 className="workEverywhere">We Work Everywhere.</h2>

          <Flex css={{ margin: "40px 0px" }} className="location-row-left">
            <CareerLocation image={"location1.png"} name="Bogota" />
            <CareerLocation image={"location2.png"} name="Los Angeles" />
            <CareerLocation image={"location3.png"} name="New York" />
            <CareerLocation image={"location4.png"} name="Medellin" />
          </Flex>

          <Flex css={{ margin: "40px 0px" }} className="location-row-right">
            <CareerLocation image={"location5.png"} name="Madrid" />
            <CareerLocation image={"location6.png"} name="Barcelona" />
            <CareerLocation image={"location7.png"} name="Mexico City" />
            <CareerLocation image={"location8.png"} name="Toronto" />
          </Flex>

          <Flex css={{ margin: "40px 0px" }} className="location-row-left">
            <CareerLocation image={"location9.png"} name="San Fransisco" />
            <CareerLocation image={"location8.png"} name="Chicago" />
            <CareerLocation image={"location2.png"} name="Los Angeles" />
            <CareerLocation image={"location8.png"} name="Toronto" />
          </Flex>

          <Flex css={{ margin: "40px 0px" }} className="location-row-right">
            <CareerLocation image={"location1.png"} name="Bogota" />
            <CareerLocation image={"location2.png"} name="Los Angeles" />
            <CareerLocation image={"location3.png"} name="New York" />
            <CareerLocation image={"location4.png"} name="Medellin" />
          </Flex>
        </Box>
        <Box
          className="scroll"
          css={{
            padding: "50px 0px",
            position: "relative",
            "@media screen and (max-width:430px)": {
              padding: "0px"
            }
          }}
        >
          <Flex
            className="images-main"
            css={{
              marginTop: "100px",
              // position: "relative",
              flexWrap: "wrap",

              justifyContent: "center",
              " @media screen and (max-width:1240px)": {
                display: "none"
              }
            }}
          >
            <Box
              className="images-item"
              css={{
                marginRight: "10px",
                marginTop: "130px",
                width: "24%",
                maxWidth: "340px",
                position: "relative"
              }}
            >
              <Image
                className="lazyload"
                src={`../images/suta1.png`}
                css={{
                  height: "414px",
                  width: "100%",
                  marginBottom: "10px",
                  borderRadius: "16px"
                }}
              />
            </Box>

            <Box
              css={{
                marginRight: "10px",
                width: "24%",
                minWidth: "300px",

                maxWidth: "340px"
              }}
              className="images-item"
            >
              <Image
                className="lazyload moving"
                src={`../images/suta2.png`}
                css={{
                  height: "414px",
                  width: "inherit",
                  maxWidth: "340px",
                  position: "absolute",
                  borderRadius: "16px"
                }}
              />
            </Box>
            <Box
              css={{
                marginRight: "10px",
                marginTop: "130px",
                width: "24%",
                minWidth: "300px",
                maxWidth: "340px"
              }}
              className="images-item"
            >
              <Image
                className="lazyload"
                src={`../images/suta3.png`}
                css={{
                  marginBottom: "10px",
                  height: "414px",
                  width: "100%",
                  borderRadius: "16px"
                }}
              />
            </Box>
            <Box
              css={{
                marginRight: "10px",
                width: "24%",
                maxWidth: "340px",
                minWidth: "300px"
              }}
              className="images-item"
            >
              <Image
                className="lazyload moving"
                src={`../images/suta4.png`}
                css={{
                  " @media screen and (max-width:1024px)": {
                    width: "100%",
                    position: "relative"
                  },
                  width: "inherit",
                  maxWidth: "340px",
                  minWidth: "300px",

                  height: "414px",
                  position: "absolute",
                  borderRadius: "16px"
                }}
              />
            </Box>
          </Flex>
          <Flex
            css={{
              display: "none",
              marginTop: "100px",
              // position: "relative",
              flexWrap: "wrap",

              justifyContent: "center",
              " @media screen and (max-width:1240px)": {
                display: "flex"
              }
            }}
          >
            <Box
              css={{
                marginRight: "10px",
                // marginTop: "130px",
                width: "24%",
                marginBottom: "10px",
                maxWidth: "340px",
                paddingTop: "14%",

                minWidth: "40%",
                position: "relative"
              }}
            >
              <Image
                className="lazyload"
                src={`../images/suta1.png`}
                css={{
                  height: "fit-content",
                  width: "100%",
                  marginBottom: "10px",
                  borderRadius: "16px"
                }}
              />
            </Box>

            <Box
              css={{
                marginRight: "10px",
                width: "24%",
                minWidth: "40%",
                marginBottom: "10px",
                maxWidth: "340px"
              }}
            >
              <Image
                className="lazyload"
                src={`../images/suta2.png`}
                css={{
                  height: "fit-content",
                  width: "100%",
                  marginBottom: "10px",

                  borderRadius: "16px"
                }}
              />
            </Box>
            <Box
              css={{
                marginRight: "10px",
                // marginTop: "130px",
                paddingTop: "5%",

                marginBottom: "10px",

                width: "24%",
                minWidth: "40%",
                maxWidth: "340px"
              }}
            >
              <Image
                className="lazyload"
                src={`../images/suta3.png`}
                css={{
                  marginBottom: "10px",
                  height: "fit-content",
                  width: "100%",
                  borderRadius: "16px"
                }}
              />
            </Box>
            <Box
              css={{
                marginRight: "10px",
                width: "24%",
                maxWidth: "340px",
                marginTop: "-8%",

                minWidth: "40%"
              }}
            >
              <Image
                className="lazyload"
                src={`../images/suta4.png`}
                css={{
                  height: "fit-content",
                  width: "100%",
                  marginBottom: "10px",
                  borderRadius: "16px"
                }}
              />
            </Box>
          </Flex>
        </Box>

        <Flex
          css={{
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundColor: "#464646",
            height: "700px",
            marginTop: "150px",
            "@media screen and (max-width:430px)": {
              backgroundColor: "unset",
              backgroundSize: "900px",
              height: "420px",
              marginTop: "100px"
            },
            backgroundImage: `url(https://herb-platform-images.imgix.net/wp-content/uploads/2022/03/16091421/career-banner.png)`
          }}
        >
          <div className="careerBannerTitle">
            <h1 className="careerBannerText">
              Are you ready for your new career?
            </h1>
          </div>
          <div className="careerBannerButton">
            <Button type={"careerButton"} to="/positions">
              view open positions
            </Button>
          </div>
        </Flex>
      </Box>
    </Layout>
  );
};

export default Careers;

export const pageQuery = graphql`
  query {
    allWordpressWpCareer(sort: { fields: [title], order: [ASC] }) {
      edges {
        node {
          title
          id
          slug
          contentCareers {
            location
            description
          }
        }
      }
    }
  }
`;
